import { MAILER_ENDPOINT, EMAIL_ADDRESS } from '/src/constants';
import getOrderConfirmationHTML from './getOrderConfirmationHTML';

export default ({ to, subject, html }) =>
  fetch(MAILER_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      from: EMAIL_ADDRESS,
      to,
      subject,
      html,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

export { getOrderConfirmationHTML };
