import moment from 'moment';

export default class Validator {
  constructor(values = {}) {
    this.values = values;
    this.errors = {};
  }

  setValues(values) {
    this.values = values;
    this.errors = {};
    return this;
  }

  getErrors() {
    return this.errors;
  }

  // generic fields

  isRequired(fields, message = 'Required') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if ((!value || value.length === 0) && value !== 0) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  notIn(fields, list, message = 'No duplicates allowed') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (list && value && list.indexOf(value.trim()) !== -1) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  equals(fields, value, message = 'Must match') {
    fields.forEach(fieldName => {
      const fieldValue = this.values[fieldName];
      if (fieldValue !== value) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  // string fields

  matches(fields, exp, message) {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      const match = value && value.match(exp);

      if (value && (!match || !match[0])) {
        this.errors[fieldName] = message || `Must match ${exp}`;
      }
    });

    return this;
  }

  notMatches(fields, exp, message) {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      const match = value && value.match(exp);

      if (value && match && match[0]) {
        this.errors[fieldName] = message || `Not allowed: ${match[0]}`;
      }
    });

    return this;
  }

  maxLength(fields, max, message) {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (value && value.length > max) {
        this.errors[fieldName] = message || `Maximum length (${max}) exceeded`;
      }
    });

    return this;
  }

  stringNotIn(fields, list, message = 'No duplicates allowed') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (
        list &&
        value &&
        list
          .map(item => item.toLowerCase())
          .indexOf(value.trim().toLowerCase()) !== -1
      ) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  // numeric fields

  isNumber(fields, message = 'Must be a number') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (Number.isNaN(Number(value))) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  isInt(fields, message = 'Must be a whole number') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (
        !Number.isNaN(Number(value)) &&
        value !== '' &&
        Number(value) % 1 !== 0
      ) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  isGreaterThan(fields, minValue, message = 'Value is below required amount') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (
        !Number.isNaN(Number(value)) &&
        value !== '' &&
        Number(value) <= minValue
      ) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  isNatural(fields, message = 'Must be 0 or greater') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (!Number.isNaN(Number(value)) && value !== '' && Number(value) < 0) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }

  // date fields

  isFuture(fields, message = 'Must be in the future') {
    fields.forEach(fieldName => {
      const value = this.values[fieldName];
      if (
        value &&
        moment(value)
          .startOf('day')
          .isBefore(moment().startOf('day'))
      ) {
        this.errors[fieldName] = message;
      }
    });

    return this;
  }
}
