import React from 'react';
import { connect } from 'react-redux';
import { Link, navigate } from 'gatsby';
import { prop } from 'ramda';
import { compose, withState, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { actions, selectors } from '/src/store/cart';
import mailer, { getOrderConfirmationHTML } from '/src/services/mailer';

import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import Modal from '/src/components/atoms/Modal';
import Button from '/src/components/atoms/Button';
import { Row, Col } from '/src/components/atoms/GridRow';
import ContentSection from '/src/components/atoms/ContentSection';
import CheckoutForm from '/src/components/organisms/CheckoutForm';
import { Table as CartSummary, Subtotal } from '/src/components/organisms/Cart';
import OrderSummary from '/src/components/molecules/OrderSummary';
import Payment from '/src/components/molecules/CartPayment';

const Checkout = ({
  cartSize,
  isModalOpen,
  openModal,
  closeModal,
  handleSuccess,
}) => (
  <Layout hideHeader>
    <SEO title="Checkout | Shop" />
    <ContentSection>
      <h1 className="heading3">Checkout</h1>
    </ContentSection>
    <ContentSection light>
      {cartSize > 0 ? (
        <>
          <Row center vertGutter>
            <Col width={6}>
              <h2 className="heading4">Summary</h2>
              <CartSummary />
              <div className="tr">
                <span>Subtotal: </span>
                <span className="bold-text">
                  <Subtotal />
                </span>
              </div>
            </Col>
          </Row>
          <Row center vertGutter>
            <Col width={6}>
              <h2 className="heading4">Personal Information</h2>
              <CheckoutForm onSuccess={openModal} />
            </Col>
          </Row>
          <Modal open={isModalOpen} onClose={closeModal}>
            <h2 className="heading3">Confirmation</h2>
            <OrderSummary />
            <h2 className="heading3">Payment</h2>
            <Payment onSuccess={handleSuccess} />
            <div className="tc">
              <Button link component={Link} to="/shop/cart">
                Edit Cart
              </Button>
            </div>
            <div className="space-sm-top tc">
              <Button link role="button" onClick={closeModal}>
                Edit Shipping or Cancel
              </Button>
            </div>
          </Modal>
        </>
      ) : (
        <Row center>
          <Col width={6} className="tc">
            <p>
              Nothing to check out! <Link to="/shop">Buy some merch!</Link>
            </p>
          </Col>
        </Row>
      )}
    </ContentSection>
  </Layout>
);

const mapStateToProps = createStructuredSelector({
  cartSize: selectors.cartSizeSelector,
  shippingEmail: selectors.emailSelector,
  eventLinks: selectors.eventLinksSelector,
  userFriendlyOrder: selectors.userFriendlyOrderSelector,
});

const mapDispatchToProps = {
  clearCart: actions.clearItems,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('isModalOpen', 'setModalOpen', false),
  withHandlers({
    openModal: ({ setModalOpen }) => () => setModalOpen(true),
    closeModal: ({ setModalOpen }) => () => setModalOpen(false),
    handleSuccess: ({
      userFriendlyOrder,
      shippingEmail,
      clearCart,
      eventLinks,
    }) => ({ response }) => {
      const created = prop('created', JSON.parse(response.order));

      mailer({
        to: shippingEmail,
        subject: 'Order Confirmation | Eye on Attraction',
        html: getOrderConfirmationHTML({
          order: { ...userFriendlyOrder, created },
          message: 'Thank you for supporting our music!',
        }),
      });

      clearCart();

      navigate('/shop/checkout/thankYou', {
        state: {
          message: `A confirmation email has been sent to ${shippingEmail}`,
          followupLinks: eventLinks,
        },
      });
    },
  })
)(Checkout);
