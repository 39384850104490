import React from 'react';

import { Row, Col } from '/src/components/atoms/GridRow';
import Items from './Items';
import Cost from './Cost';
import Shipping from './Shipping';

import './index.scss';

const OrderSummary = () => (
  <div className="OrderSummary">
    <Row>
      <Col width={12} className="OrderSummary__section">
        <h3 className="OrderSummary__heading heading4">Cart</h3>
        <Items />
      </Col>
      <Col width={6} className="OrderSummary__section">
        <h3 className="OrderSummary__heading heading4">Shipping</h3>
        <Shipping />
      </Col>
      <Col width={6} className="OrderSummary__section">
        <h3 className="OrderSummary__heading heading4">Cost</h3>
        <Cost />
      </Col>
    </Row>
  </div>
);

export default OrderSummary;

export { Items, Cost, Shipping };
