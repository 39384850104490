import { PURCHASE_ENDPOINT } from '/src/constants';

export default async ({ token, order }) =>
  fetch(PURCHASE_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      token,
      order,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
