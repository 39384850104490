import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { injectStripe, CardElement } from 'react-stripe-elements';

import purchase from '/src/services/stripe/purchase';
import { selectors } from '/src/store/cart';
import { Row, Col } from '/src/components/atoms/GridRow';
import Button from '/src/components/atoms/Button';

const Payment = ({ handleChange, handleSubmit, isSubmitting, error }) => (
  <form onSubmit={handleSubmit}>
    <Row center vertGutter>
      <Col width={7}>
        <CardElement
          onChange={handleChange}
          style={{
            base: {
              fontSize: 16,
              color: '#424770',
              letterSpacing: '0.025em',
              fontFamily: 'Source Code Pro, monospace',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          }}
        />
        <div className="error-text">{error || null}</div>
      </Col>
      <Col width={12} className="tc">
        <Button disabled={isSubmitting} className="w-100">
          Confirm and Pay
        </Button>
      </Col>
    </Row>
  </form>
);

const mapStateToProps = createStructuredSelector({
  order: selectors.orderSelector,
});

export default compose(
  connect(mapStateToProps),
  injectStripe,
  withState('error', 'setError', ''),
  withState('isCardComplete', 'setCardComplete', false),
  withState('isSubmitting', 'setSubmitting', false),
  withHandlers({
    handleChange: ({ setCardComplete, setError }) => event => {
      setError('');
      setCardComplete(event.complete);
    },
    handleSubmit: ({
      stripe,
      isCardComplete,
      order,
      onSuccess,
      setSubmitting,
      setError,
    }) => async event => {
      let responseBody;

      event.preventDefault();

      if (!isCardComplete) {
        return setError('Card information is incomplete');
      }

      setSubmitting(true);

      const { token } = await stripe.createToken({
        name: 'EoA Merch Checkout',
      });

      if (token) {
        const response = await purchase({ token, order });

        responseBody = await response.json();

        if (response.status !== 200) {
          setError(responseBody.message);
          setSubmitting(false);
        }

        if (onSuccess) {
          await onSuccess({ order, response: responseBody });
        }
      } else {
        setError('Error creating payment token. Try again or contact us!');
        setSubmitting(false);
      }

      setSubmitting(false);
    },
  })
)(Payment);
