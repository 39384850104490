import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import {
  StripeProvider,
  Elements as StripeElements,
} from 'react-stripe-elements';

import { STRIPE_PUBLISHABLE_KEY } from '/src/constants';
import Elements from './Elements';

import './index.scss';

const CartPayment = ({ onSuccess }) => (
  <NoSSR>
    <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
      <div className="CartPayment">
        <StripeElements>
          <Elements onSuccess={onSuccess} />
        </StripeElements>
      </div>
    </StripeProvider>
  </NoSSR>
);

CartPayment.propTypes = {
  onSuccess: PropTypes.func,
};

CartPayment.defaultProps = {
  onSuccess: null,
};

export default CartPayment;
