import React from 'react';
import { path } from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors } from '/src/store/cart';
import DataTable from '/src/components/molecules/DataTable';

const columns = [
  {
    title: 'Product',
    property: path(['product', 'name']),
  },
  {
    title: 'Type',
    property: path(['attributes', 'name']),
  },
  {
    title: 'Quantity',
    property: path(['quantity']),
  },
];

const ItemsSummary = ({ items }) => (
  <div className="OrderSummary__items">
    <DataTable keyProp="id" columns={columns} data={items} />
  </div>
);

const mapStateToProps = createStructuredSelector({
  items: selectors.itemsSelector,
});

export default connect(mapStateToProps)(ItemsSummary);
