import moment from 'moment';
import { EMAIL_ADDRESS } from '/src/constants';

const orderItemsRender = info => `
	<tr>
		<td>${info.name}</td>
		<td>${info.skuName}</td>
		<td>${info.price}</td>
		<td>${info.quantity}</td>
	</tr>
`;

export default ({
  order: { created, email, shipping, items, cost },
  message,
}) => `
    <div>
        <p>${shipping.firstName},</p>
  		${message ? `<p>${message}</p>` : ''}
        <p>If you did not place this order, or believe there is an issue, please contact us at <a href="mailto:${EMAIL_ADDRESS}">${EMAIL_ADDRESS}.</a></p>
        <p>This order was placed on ${moment(created * 1000).format(
          'dddd, MMMM Do YYYY, h:mma'
        )}</p>  
  	</div>
  	<div>
  		<section>
  			<h2>Order Details</h2>
            <table width="100%">
                <thead>
                    <tr>
                        <td>Item</td>
                        <td>Type</td>
                        <td>Price</td>
                        <td>Quantity</td>
                    </tr>
                </thead>
                <tbody>
                  ${items.map(orderItemsRender).join('')}
                </tbody>
  			</table>
  		</section>
  		<hr />
  		<section>
  			<h2>Cost</h2>
            <table width="50%">
                <tr>
                    <th>Subtotal</th>
                    <td>${cost.subtotal}</td>
                </tr>
                <tr>
                    <th>Tax</th>
                    <td>${cost.tax}</td>
                </tr>
                <tr>
                    <th>Shipping</th>
                    <td>${cost.shippingCost}</td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td><strong>${cost.total}</strong></td>
                </tr>
            </table>
        </section>
        <hr />
        <section>
            <h2>Shipping Info</h2>
            <table width="50%">
                <tr>
                    <th>Recipient</th>
                    <td>${shipping.firstName} ${shipping.lastName}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>${email}</td>
                </tr>
                <tr>
                    <th>Address</th>
                    <td>
                        <address>
                            ${shipping.line1}<br />
                            ${shipping.line2 ? `${shipping.line2}<br />` : ''}
                            ${shipping.city}, ${shipping.state} ${shipping.zip}
                        </address>
                    </td>
                </tr>
            </table>
        </section>
  	</div>`;
