import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors } from '/src/store/cart';

import './index.scss';

const ShippingSummary = ({
  shipping: { firstName, lastName, email, state, city, line1, line2, zip },
}) => (
  <div className="OrderSummary__shipping">
    <div className="OrderSummary__item OrderSummary__shipping__name">
      <span className="OrderSummary__label">Name</span>
      <span className="OrderSummary__value">{`${firstName} ${lastName}`}</span>
    </div>
    <div className="OrderSummary__item OrderSummary__shipping__email">
      <span className="OrderSummary__label">Email</span>
      <span className="OrderSummary__value">{email}</span>
    </div>
    <div className="OrderSummary__item OrderSummary__shipping__address">
      <span className="OrderSummary__label">Address</span>
      <span className="OrderSummary__value">
        <address>
          {line1}
          <br />
          {line2 && (
            <>
              {line2}
              <br />
            </>
          )}
          {`${city}, ${state} ${zip}`}
        </address>
      </span>
    </div>
  </div>
);

const mapStateToProps = createStructuredSelector({
  shipping: selectors.shippingSelector,
});

export default connect(mapStateToProps)(ShippingSummary);
