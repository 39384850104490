import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors } from '/src/store/cart';

import './index.scss';

const CostSummary = ({
  subtotalString,
  taxString,
  shippingCostString,
  totalString,
}) => (
  <div className="OrderSummary__cost">
    <div className="OrderSummary__item OrderSummary__cost__subtotal">
      <span className="OrderSummary__label">Subtotal</span>
      <span className="OrderSummary__value">{subtotalString}</span>
    </div>
    <div className="OrderSummary__item OrderSummary__cost__tax">
      <span className="OrderSummary__label">Tax</span>
      <span className="OrderSummary__value">{taxString}</span>
    </div>
    <div className="OrderSummary__item OrderSummary__cost__shipping">
      <span className="OrderSummary__label">Shipping</span>
      <span className="OrderSummary__value">{shippingCostString}</span>
    </div>
    <div className="OrderSummary__item OrderSummary__cost__total">
      <span className="OrderSummary__label">Total</span>
      <span className="OrderSummary__value">{totalString}</span>
    </div>
  </div>
);

const mapStateToProps = createStructuredSelector({
  subtotalString: selectors.subtotalStringSelector,
  taxString: selectors.taxStringSelector,
  shippingCostString: selectors.shippingCostStringSelector,
  totalString: selectors.totalStringSelector,
});

export default connect(mapStateToProps)(CostSummary);
