import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { UsaStates } from 'usa-states';
import { withFormik } from 'formik';
import Validator from '/src/utils/Validator';

import { selectors, actions } from '/src/store/cart';
import { Row, Col } from '/src/components/atoms/GridRow';
import Button from '/src/components/atoms/Button';
import FormGroup from '/src/components/molecules/FormGroup';

const states = new UsaStates().states;

const shippingFields = [
  'firstName',
  'lastName',
  'email',
  'state',
  'city',
  'line1',
  'line2',
  'zip',
];

const CheckoutForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col width={6}>
        <FormGroup
          id="firstName"
          name="firstName"
          label="First Name"
          value={values.firstName}
          errors={touched.firstName && errors.firstName}
          onChange={handleChange}
        />
      </Col>
      <Col width={6}>
        <FormGroup
          id="lastName"
          name="lastName"
          label="Last Name"
          value={values.lastName}
          errors={touched.lastName && errors.lastName}
          onChange={handleChange}
        />
      </Col>
      <Col width={12}>
        <FormGroup
          id="email"
          name="email"
          label="Email Address"
          value={values.email}
          errors={touched.email && errors.email}
          onChange={handleChange}
        />
      </Col>
      <Col width={12}>
        <FormGroup
          id="line1"
          name="line1"
          label="Address line 1"
          value={values.line1}
          errors={touched.line1 && errors.line1}
          onChange={handleChange}
        />
      </Col>
      <Col width={12}>
        <FormGroup
          id="line2"
          name="line2"
          label="Address line 2 (apartment, suite, etc)"
          value={values.line2}
          errors={touched.line2 && errors.line2}
          onChange={handleChange}
        />
      </Col>
      <Col width={4}>
        <FormGroup
          id="state"
          name="state"
          label="State"
          inputComponent="select"
          value={values.state}
          errors={touched.state && errors.state}
          onChange={handleChange}
          inputProps={{
            children: states.map(({ name, abbreviation }) => (
              <option key={abbreviation} value={abbreviation}>
                {name}
              </option>
            )),
          }}
        />
      </Col>
      <Col width={4}>
        <FormGroup
          id="city"
          name="city"
          label="City"
          value={values.city}
          errors={touched.city && errors.city}
          onChange={handleChange}
        />
      </Col>
      <Col width={4}>
        <FormGroup
          id="zip"
          name="zip"
          label="ZIP Code"
          value={values.zip}
          errors={touched.zip && errors.zip}
          onChange={handleChange}
        />
      </Col>
    </Row>
    <Row>
      <Col width={12} className="tr">
        <Button type="submit" className="w-100">
          Continue
        </Button>
      </Col>
    </Row>
  </form>
);

CheckoutForm.propTypes = {
  onSuccess: PropTypes.func,
  // generated
  shipping: PropTypes.shape(),
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  setShipping: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

CheckoutForm.defaultProps = {
  onSuccess: () => {},
  shipping: {},
  values: {},
  touched: {},
  errors: {},
};

const mapStateToProps = createStructuredSelector({
  shipping: selectors.shippingSelector,
});

const mapDispatchToProps = {
  setShipping: actions.setShipping,
};

const validator = new Validator();

const validate = values =>
  validator
    .setValues(values)
    .isRequired(shippingFields.filter(field => field !== 'line2'), 'Required')
    .getErrors();

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    displayName: 'Checkout information',
    mapPropsToValues: ({ shipping }) => ({ ...shipping }),
    validate,
    handleSubmit: (values, { props: { onSuccess, setShipping } }) => {
      setShipping(pick(shippingFields, values));

      if (onSuccess) {
        onSuccess(values);
      }
    },
  })
)(CheckoutForm);
