import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import keyCodes from '/src/utils/keyCodes';

import './index.scss';

const lockBody = doLock => {
  document.body.style.overflow = doLock ? 'hidden' : 'auto';
};

class Modal extends React.Component {
  componentDidMount() {
    const { open } = this.props;

    lockBody(!!open);
    if (open && !!this.dialog.current) {
      this.focusDialog();
    }
  }

  componentWillUnmount() {
    lockBody(false);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    const { open: prevOpen } = prevProps;

    // listen to changes on props.open
    if (prevOpen !== open) {
      lockBody(!!open);

      if (open && !!this.dialog.current) {
        this.focusDialog();
      }
    }
  }
  dialog = React.createRef();

  handleKeys = event => {
    if ((event.which || event.keyCode) === keyCodes.ESC) {
      this.props.onClose();
    }
  };

  focusDialog = () => this.dialog.current.focus();

  render() {
    const { open, title, children, onClose } = this.props;

    return (
      typeof window === 'object' &&
      createPortal(
        <div
          className={classNames('Modal', {
            'Modal--open': !!open,
          })}
        >
          <div className="Modal__overlay" onClick={onClose} />
          <div tabIndex="0" onFocus={this.focusDialog} />
          <div
            role="dialog"
            aria-modal="true"
            aria-hidden={!open}
            className="Modal__dialog"
            tabIndex="-1"
            onKeyUp={this.handleKeys}
            ref={this.dialog}
          >
            <div className="Modal__dialog__inner">
              <button
                className="Modal__dialog__inner__close btn"
                aria-label="Close dialog"
                onClick={onClose}
              >
                &times;
              </button>
              {title && (
                <div className="Modal__dialog__inner__header">
                  <h2 className="Modal__dialog__inner__header__title">
                    {title}
                  </h2>
                </div>
              )}
              <div className="Modal__dialog__inner__body" role="document">
                {children}
              </div>
            </div>
          </div>
          <div tabIndex="0" onFocus={this.focusDialog} />
        </div>,
        window.document.getElementById('portal__modal')
      )
    );
  }
}

Modal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  open: false,
  title: null,
  children: null,
  onClose: null,
};

export default Modal;
